import React from "react";
import { IonRow, IonCol, IonIcon, IonGrid } from "@ionic/react";
import { logoWhatsapp, logoInstagram, logoFacebook, globeOutline } from "ionicons/icons";
import { InfoLayoutProps } from './types';

import './InfoLayout.scss';

const InfoLayout: React.FC<InfoLayoutProps> = ({ title, hideSubtitle }) => {
  return (
    <div
      className="info-layout"
    >
      <IonGrid>
        <IonRow>
          <IonCol>
            <div className="title">{title}</div>
          </IonCol>
        </IonRow>

        <IonRow className="ion-margin-vertical">
          <IonCol>
            <div className="ion-margin-bottom">¿Tenes alguna consulta o estas interesado en trabajar con FielPet o FielVet?</div>

            <div>Comunicate con nosotros por WhatsApp</div>

            <div className="ion-margin-vertical">
              <a href="https://wa.me/5493412828296" target="_blank"><IonIcon icon={logoWhatsapp} /></a>
            </div>

            <div>o via email a</div>

            <a href="mailto:info@fielpet.com.ar">info@fielpet.com.ar</a>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <div>
              Conoce más de nosotros
            </div>
          </IonCol>
        </IonRow>

        <IonRow className="ion-margin-top">
          <IonCol size="4">
            <a href="https://www.instagram.com/fiel.pet/">
              <IonIcon icon={logoInstagram} />
            </a>
          </IonCol>

          <IonCol>
            <a href="https://www.facebook.com/FielPet-2038763733099486">
              <IonIcon icon={logoFacebook} />
            </a>
          </IonCol>

          <IonCol>
            <a href="https://www.fielpet.com.ar">
              <IonIcon icon={globeOutline} />
            </a>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default InfoLayout;