const API_URL_LOCAL = 'http://api.localhost:8000';
const API_URL_DEVELOPMENT = "https://test.fielpet.com";
const API_URL_PRODUCTION = "https://api.fielpet.com.ar";
// const API_URL_LOCAL = "https://api.fielpet.com.ar/test";
// const API_URL_LOCAL = "http://192.168.0.69:8000";

const LOGIN = "/auth/login";
const LOGOUT = "/auth/logout";
const REFRESH = "/auth/refresh";
const PETS = "/app/user/mascotas";
const GET_PLANS = "/web/planes";
const REGISTER = "/auth/register";
const RECOVERYPASS = "/auth/password";
const ADD_WEIGHT = "/app/user/mascotas/historial-peso";
const UPDATE_PROFILE_PIC = "/app/user/mascotas/profilepic";
const ASSOC_PETS = "/app/user/mascotas/generar-link";
const DATOS_PAGO = "/app/user/datospagos";
const GET_PAYMENT = "/web/mediopagos";
const GET_RAZAS = "/web/razas";
const GET_LOCALIDADES = "/app/public/localidades";
const PERSONAL_DATA = "/app/user/personas";
const EVENTO = "/app/user/eventos";
const GET_VACUNA = "/app/user/vacunas";
const GET_PRODUCTOS_DESPARASITACION = "/app/user/productos";
const GET_EVENTS = "/app/user/eventos/timeline";
const NOTIFICATIONS_SUBSCRIBE = "app/user/notificaciones/suscribir";
const ADD_FOOD = "/app/user/stock-alimento";
const GET_FOOD_BAGS = "/app/public/alimentos";
const GET_A_FOOD = "/app/user/stock-alimento";
const GET_CONSUMO = "/app/user/stock-alimento/calcular";
const GET_FOOD_PET = "/app/user/stock-alimento/mascota";

// MODULO SINIESTROS
const GET_VETFORM = '/app/vet-form/vet-form-link-ref';
const GET_VETFORM_DATA = '/app/vet-form/vet-form';
const VET_FORM = '/app/vet-form/submit';
const SUBVETFORMS_SUELTOS = '/app/vet-form/sub-vet-form-suelto';
const SORTEO = '/app/vet-form/ganadores';

const GET_VETFORMLINK = "/app/vet-form/vet-form-link-ref";
const ADD_VETFORM_LINK_DATA = "/app/vet-form/link";
const ADD_VETFORM_LINK_DATA_FIESTA = "/app/vet-form/link2";
const GET_SINTOMAS_MASCOTA = "app/sintomas/sintomas-siniestro";
const GET_DATOS_USUARIO = "app/vet-form/datos-usuario";
const GET_ESTUDIOS = "app/vet-form/estudios";
const GET_PRACTICAS = "app/vet-form/practicas";

const GET_VETERINARIO_VETFORM = "/app/veterinario/veterinario-vetform";
const GET_VETERINARIOS_SINIESTRO = "app/veterinario/veterinario-siniestro";

const GET_VETERINARIO = "/app/veterinario/veterinario";
const GET_VETERINARIOS = "/app/veterinario/all-veterinarios";

const GET_VETERINARIOS_RECOMENDADOS = "/app/veterinario/sugeridos-sub-vet-form";

const SINIESTROS = "/app/siniestro/create-siniestro";
const GET_SINIESTRO = "/app/siniestro/siniestro";
const GET_SINIESTROS = "/app/siniestro/siniestros";
const GET_MOV_SINIESTRO = "/app/mov-siniestro/get-mov-siniestro";

const GET_INDICACIONES = "/app/vet-form/sub-vet-form";
const ADD_COMPROBANTE_FILE = "app/siniestro/add-comprobante-file";
const GET_MEDICAMENTO = "/app/medicamentos/medicamento";
const GET_LABORATORIO = "/app/laboratorio";

const ADD_DNI_FILE = "app/siniestro/add-dni-file";
const ADD_LIBRETA_VACUNACION_FILE = "app/siniestro/add-libreta-file";
const ADD_CBU_FILE = "app/siniestro/add-cbu-file";
const GET_DOCUMENTACION_EXISTENTE = "/app/siniestro/documentacion";
// END MODULO SINIESTROS

const GET_SINTOMAS = "app/sintomas/sintomas";
const GET_SISTEMAS = "app/sintomas/sistemas";
const GET_MEDICAMENTOS = "app/medicamentos/";
const GET_ENFERMEDADES = "app/diagnosticos/";

const API_URL: {
  [key: string]: string;
} = {
  local: API_URL_LOCAL,
  dev: API_URL_DEVELOPMENT,
  prod: API_URL_PRODUCTION,
};

export {
  API_URL,
  LOGIN,
  LOGOUT,
  REGISTER,
  RECOVERYPASS,
  PETS,
  ADD_WEIGHT,
  UPDATE_PROFILE_PIC,
  ASSOC_PETS,
  REFRESH,
  GET_PLANS,
  DATOS_PAGO,
  GET_PAYMENT,
  GET_RAZAS,
  GET_LOCALIDADES,
  PERSONAL_DATA,
  EVENTO,
  GET_VACUNA,
  GET_PRODUCTOS_DESPARASITACION,
  GET_EVENTS,
  NOTIFICATIONS_SUBSCRIBE,
  ADD_FOOD,
  GET_FOOD_BAGS,
  GET_A_FOOD,
  GET_CONSUMO,
  GET_FOOD_PET,
  // MODULO SINIESTROS
  VET_FORM,
  GET_VETFORM,
  SUBVETFORMS_SUELTOS,
  ADD_VETFORM_LINK_DATA,
  ADD_VETFORM_LINK_DATA_FIESTA,
  GET_VETFORMLINK,
  GET_VETERINARIOS_SINIESTRO,
  GET_VETERINARIO,
  GET_VETERINARIO_VETFORM,
  GET_VETERINARIOS,
  GET_VETERINARIOS_RECOMENDADOS,
  GET_SINTOMAS,
  GET_SISTEMAS,
  GET_SINTOMAS_MASCOTA,
  GET_ENFERMEDADES,
  GET_MEDICAMENTOS,
  GET_LABORATORIO,
  GET_DATOS_USUARIO,
  SINIESTROS,
  GET_SINIESTRO,
  GET_SINIESTROS,
  GET_MOV_SINIESTRO,
  GET_INDICACIONES,
  ADD_COMPROBANTE_FILE,
  ADD_DNI_FILE,
  ADD_LIBRETA_VACUNACION_FILE,
  ADD_CBU_FILE,
  GET_DOCUMENTACION_EXISTENTE,
  GET_MEDICAMENTO,
  GET_PRACTICAS,
  GET_ESTUDIOS,
  GET_VETFORM_DATA,
  SORTEO,
  // END MODULO SINIESTROS
};

